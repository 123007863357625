<template>
  <div>
    <div v-show="isLoading">
      <OtherLoading />
    </div>
    <div v-show="!isLoading">
      <b-row class="no-gutters mb-3">
        <b-col>
          <div class="font-weight-bold ft-20 header-tablepage">
            Redeem Detail
          </div>
        </b-col>
      </b-row>
      <div>
        <b-tabs>
          <b-tab title="Information" @click="tabCurrent = 1">
            <RedeemForm
              :id="id"
              :form="form"
              :is_redeem="info.totalRedeem > 0"
              :v="$v.form"
              :memberList="memberList"
              :memberError="memberError"
              v-on:openProductModal="openProductModal"
              ref="RedeemDetail"
            />
          </b-tab>
          <b-tab title="Report" @click="tabCurrent = 2" v-if="id != 0">
            <RedeemReport
              :id="id"
              :data="info"
              ref="RedeemOrderList"
              v-on:confirmAction="confirmAction"
            />
          </b-tab>
          <b-tab title="Theme">
            <RedeemTheme :form="form" />
          </b-tab>
          <b-tab title="Setting">
            <RedeemSetting :form="form" />
          </b-tab>
        </b-tabs>
      </div>

      <FooterAction @submit="checkForm" routePath="/redeem" />

      <ProductModal
        :form="form"
        @openProductModal="openProductModal"
        v-on:submitProduct="submitProduct"
        :freezeObj="freezeObj"
        ref="ProductListModal"
      />
    </div>
    <ModalConfirm
      ref="modalConfirm"
      :text="confirmText"
      @handler="confirmHideModal"
    />
  </div>
</template>

<script>
import RedeemForm from "@/components/redeem/detail/RedeemForm";
import RedeemReport from "@/components/redeem/report/RedeemReport.vue";
import RedeemTheme from "@/components/redeem/detail/RedeemTheme.vue";
import RedeemSetting from "@/components/redeem/detail/RedeemSetting.vue";
import OtherLoading from "@/components/loading/OtherLoading";
import { required, requiredIf, minValue } from "vuelidate/lib/validators";
import ProductModal from "@/components/redeem/modal/ProductModal.vue";
import ModalConfirm from "@/components/modal/ModalConfirm";
import * as moment from "moment/moment";
export default {
  name: "RedeemDetail",
  components: {
    OtherLoading,
    RedeemForm,
    RedeemReport,
    RedeemTheme,
    RedeemSetting,
    ProductModal,
    ModalConfirm,
  },
  data() {
    return {
      id: parseInt(this.$route.params.id),
      isLoading: false,
      isDisable: false,
      tabCurrent: 1,
      form: {
        RedeemOrderId: this.id,
        Name: "",
        totalRedeemStatus: false,
        Total_Redeemable: 0,
        Skip: 1,
        pick_Up_Type_Id: 1,
        Status: 1,
        redeemType: 1,
        Valid_From: "",
        Valid_To: "",
        items: {
          selectProduct: [],
          DeleteProduct: [],
        },
        is_show_customer: 1,
        allow_customer_to_redeem: 1,
        redeem_condition_message: "",
        theme_primary_color: "#9929bd",
        theme_secondary_color: "#9929bd",
        theme_icon_color: "#9929bd",
        theme_background: "#9929bd",
        theme_background_page: 1,
        user_limit: 0,
        total_Redeem_Day: 0,
        sort_order: 0,
        stock_branch_id: "",
      },
      info: {
        totalRedeem: 0,
        received: 0,
        waitForReceived: 0,
        cancel: 0,
      },
      filter: {
        redeemId: parseInt(this.$route.params.id),
        page: 1,
        length: 10,
        start: 0,
        search: "",
        branchId: 0,
        DeleteProductList: [],
        selectProductList: [],
        redeemOrderStatus: 0,
      },
      actionStatus: "",
      confirmText: "",
      submitType: 0,
      rows: 0,
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      freezeObj: [],
      memberList: [],
      memberError: false,
    };
  },
  validations: {
    form: {
      Name: { required },
      Total_Redeemable: {
        required: requiredIf(function () {
          return this.form.totalRedeemStatus;
        }),
        minValue: function () {
          if (
            this.form.totalRedeemStatus &&
            parseInt(this.form.Total_Redeemable) <= 0
          ) {
            return false;
          } else {
            return true;
          }
        },
      },
      stock_branch_id: {
        required: requiredIf(function () {
          return this.form.pick_Up_Type_Id != 1;
        }),
      },
      Valid_From: { required },
      Valid_To: { required },
      items: {
        selectProduct: {
          required: requiredIf(function () {
            return this.form.items.selectProduct.length == 0 && this.id == 0;
          }),
          $each: {
            required,
            point_used: { required, minValue: minValue(0) },
          },
        },
      },
    },
  },
  created() {
    this.getMemberLevel();
    if (this.id != 0) this.getRedeemOrderList();
  },
  destroyed() {},
  methods: {
    async getMemberLevel() {
      await this.$store.dispatch("getDropdown");
      const data = this.$store.state.memberTier.dropdown;
      if (data.result == 1) {
        this.memberList = data.detail;
        this.form.member_id_list = data.detail.map((el) => el.id);
        // this.upgradeTier = data.detail;
        // this.isLoading = false;
      }
    },
    async getRedeemOrderList() {
      this.isLoading = true;
      this.isBusy = true;

      await this.$store.dispatch("getRedeemDetail", this.filter);
      const data =
        this.$store.state.redeem.redeemDetail.detail.data.redeemDetail;
      this.$refs.RedeemDetail.filter.selectProductList =
        this.$store.state.redeem.redeemDetail.detail.data.redeemItemList.map(
          (el) => el.redeem_target_id
        );

      const result = this.$store.state.redeem.redeemDetail.result;
      this.freezeObj =
        this.$store.state.redeem.redeemDetail.detail.data.redeemItemList;
      if (result == 1) {
        this.form = {
          RedeemOrderId: this.id,
          Name: data.redeem_Name,
          totalRedeemStatus: data.total_Redeemable > 0 ? true : false,
          Total_Redeemable: data.total_Redeemable,
          Skip: this.id == 0 ? 1 : data.redeem_Skip,
          Status: data.status,
          member_id_list: data.member_id_list,
          pick_Up_Type_Id:
            data.pick_Up_Type_Id === 0 ? 1 : data.pick_Up_Type_Id,
          redeemType: 1,
          Valid_From: data.valid_From ? moment(data.valid_From).format() : "",
          Valid_To: data.valid_To ? moment(data.valid_To).format() : "",
          items: {
            selectProduct: [],
            DeleteProduct: [],
          },
          theme_primary_color: data.theme_primary_color,
          theme_secondary_color: data.theme_secondary_color,
          theme_icon_color: data.theme_icon_color,
          theme_background: data.theme_background,
          theme_background_page: data.theme_background_page,
          is_show_customer: this.id == 0 ? 1 : data.is_show_customer,
          allow_customer_to_redeem:
            this.id == 0 ? 1 : data.allow_customer_to_redeem,
          redeem_condition_message: data.redeem_condition_message,
          user_limit: data.user_limit,
          total_Redeem_Day: data.total_Redeem_Day,
          sort_order: data.sort_order,
          stock_branch_id: data.stock_branch_id || "",
        };

        if (data.count_Redeemed > 0) {
          this.memberList = this.memberList.map((el) => {
            return {
              ...el,
              disabled: this.form.member_id_list.includes(parseInt(el.id)),
            };
          });
        }

        this.info = {
          totalRedeem: data.count_Redeemed,
          received: data.received,
          waitForReceived: data.wait_For_Receive,
          cancel: data.canceled,
        };
        this.$refs.RedeemDetail.filter.selectProductList =
          this.form.items.selectProduct;

        this.$refs.RedeemDetail.getProductSelectList(0);
        if (this.id != 0) {
          this.$refs.RedeemOrderList.getRedeemOrderList();
          // this.$refs.RedeemOrderList.getBranchList();
        }
      }
      this.isLoading = false;
      this.isBusy = false;
    },
    async checkForm(flag) {
      this.memberError = false;
      this.form.Status = this.form.Status ? 1 : 0;
      this.form.items.DeleteProduct =
        this.$refs.RedeemDetail.filter.DeleteProductList;
      this.$refs.RedeemDetail.filter.DeleteProductList.map((el) =>
        el.redeem_target_id ? (el.id = el.redeem_target_id) : (el.id = el.id)
      );
      this.$refs.RedeemDetail.filter.selectProductList.map((el) =>
        el.redeem_target_id ? (el.id = el.redeem_target_id) : (el.id = el.id)
      );

      await this.$refs.RedeemDetail.setProductPoint();

      this.$v.form.$touch();
      if (this.form.member_id_list.length < 1) {
        return (this.memberError = true);
      }
      if (this.$v.form.$error) {
        return;
      }

      this.submitForm();
    },
    async submitForm() {
      try {
        this.form.items.selectProduct = this.form.items.selectProduct.filter(
          (el) => el.redeem_type_id != undefined
        );
        this.form.total_Redeem_Day = this.form.total_Redeem_Day
          ? this.form.total_Redeem_Day
          : 0;
        this.form.stock_branch_id = this.form.stock_branch_id
          ? this.form.stock_branch_id
          : 0;
        this.$bus.$emit("showLoading");
        await this.$store.dispatch("createOrUpdateRedeem", this.form);
        const data = this.$store.state.redeem.createOrUpdate;
        if (data.result == 1) {
          this.successAlert().then(
            (value) => value && this.$router.push("/redeem")
          );
        } else {
          this.errorAlert(data.message);
        }
        this.$bus.$emit("hideLoading");
      } catch (error) {
        this.$bus.$emit("hideLoading");
        console.log(error);
      }
    },
    confirmAction() {
      this.confirmHideModal();
    },
    confirmHideModal(e) {
      if (this.actionStatus == "save") {
        this.submitForm();
      } else if (this.actionStatus == "delete") {
        this.deleteData();
      } else {
        this.$refs.RedeemOrderList.changeStatus();
      }
    },
    async openProductModal(type) {
      this.$refs.ProductListModal.show(
        type,
        this.form.items.selectProduct.map((e) => e.redeem_target_id || e.id)
      );
    },
    submitProduct(select) {
      this.$refs.RedeemDetail.filter.DeleteProductList = this.freezeObj.filter(
        (el) => !select.find((els) => el.redeem_target_id == els.id)
      );
      this.$refs.RedeemDetail.filter.DeleteProductList.map(
        (el) => (el.id = el.redeem_target_id)
      );
      this.$refs.RedeemDetail.filter.selectProductList =
        this.$refs.RedeemDetail.filter.selectProductList.filter((el) =>
          select.find((els) => el.id == els.id)
        );
      this.form.items.selectProduct = select;
      const itemsList = select.filter(
        (el) =>
          !this.$refs.RedeemDetail.filter.selectProductList.find(
            (els) => el.id == els.id
          )
      );

      this.$refs.RedeemDetail.filter.selectProductList = [
        ...this.$refs.RedeemDetail.filter.selectProductList,
        ...itemsList,
      ];

      this.$refs.RedeemDetail.filter.start = 0;
      this.$refs.RedeemDetail.filter.page = 1;
      this.$refs.RedeemDetail.getProductSelectList(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.ft-20 {
  font-size: 20px;
}
::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
.footer {
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 256px;
  right: 0;
  padding: 10px;
  background: #fff;
  z-index: 999;
}
.btn-template {
  color: var(--primary-color);
  border-color: var(--primary-color);
  background-color: transparent;
  border-radius: 0px;
  width: 30%;
}
.btn-confirm-automation {
  color: #fff;
  border-radius: 0px;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  width: 30%;
}
.btn-cancel-automation {
  color: #fff;
  background-color: #39424e;
  border-radius: 0px;
  width: 30%;
  border: 1px solid #39424e;
}
.btn-delete-automation {
  background-color: transparent;
  border: 1px solid #39424e;
  border-radius: 0px;
  width: 30%;
  color: #39424e;
}
::v-deep .nav-tabs .nav-link {
  width: 150px;
  text-align: center;
}
</style>
