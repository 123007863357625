<template>
  <b-modal
    ref="ModalInputEmail"
    v-model="showModal"
    centered
    :title="title"
    hide-footer
    @hidden="handleCloseModal"
  >
    <p class="my-1">
      {{ description }}
    </p>
    <InputText
      textFloat="Email"
      placeholder="Email"
      type="text"
      name="email"
      isRequired
      v-model="form[keyForm]"
      @onKeyup="handleChangeEmail"
      @onKeypress="handleKeyPress"
      :isValidate="errorEmail"
    />
    <div v-if="errorEmail">
      <span class="alert-email"
        >Please enter your email address (e.g. email@example.com)</span
      >
    </div>
    <b-button
      class="mt-3 btn-email"
      block
      ref="submit-button"
      :disabled="hasEmailorOnSubmit"
      @click="submitForm()"
      >Submit</b-button
    >
  </b-modal>
</template>

<script>
import InputText from "@/components/inputs/InputText";
export default {
  components: {
    InputText,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    title: {
      required: false,
      default: "Add Email",
    },
    description: {
      required: false,
      default:
        "Please enter your email , We will send import result to your email",
    },
    keyForm: {
      default: "email",
      required: false,
    },
  },
  data() {
    return {
      showModal: false,
      hasEmailorOnSubmit: false,
      errorEmail: false,
    };
  },
  created() {
    this.errorEmail = false;
    if (!this.form[this.keyForm]) this.hasEmailorOnSubmit = true;
  },
  methods: {
    show() {
      this.showModal = true;
      this.errorEmail = false;
      this.form[this.keyForm] = this.$cookies.get("back_office_admin_email");
      if (this.form[this.keyForm]) this.hasEmailorOnSubmit = false;
    },
    handleKeyPress(e) {
      if (e.keyCode == 13) this.$refs["submit-button"].click();
    },
    close() {
      this.showModal = false;
    },
    handleChangeEmail(value) {
      let mailformat =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.form[this.keyForm]) {
        if (mailformat.test(this.form[this.keyForm])) {
          this.hasEmailorOnSubmit = false;
          this.errorEmail = false;
          this.$emit("changeEmail", this.form[this.keyForm]);
        } else {
          this.hasEmailorOnSubmit = true;
          this.errorEmail = true;
        }
      } else {
        this.hasEmailorOnSubmit = true;
      }
    },
    handleCloseModal() {
      this.$emit("closeModal");
    },
    submitForm() {
      this.$emit("submit");
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-email {
  background-color: var(--primary-color);
  color: #fff;
}
.alert-email {
  color: red;
}
::v-deep .modal-title {
  font-weight: 600;
  font-size: 20px;
}
</style>
